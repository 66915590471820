export const getStrapiUrl = () => {

  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
  //  return "http://localhost:1337";
  }

  return "https://strapi.reischauer.at";

}
